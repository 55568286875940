import React, { useState } from "react";

interface RedisPageProps { }

const RedisPage: React.FC<RedisPageProps> = () => {
  const [result, setResult] = useState("");

  const handleLogic = () => {
    const host = process.env.API_HOST
    const data = "Hello !!!111one one one.";

    console.log(host, data);

    fetch("https://backend.staging-5em2ouy-spep2waqsjevk.eu-5.platformsh.site/")
      .then((response) => {
        setResult(data);
      })
  };

  return (
    <div style={{ padding: "20px" }}>
      <button onClick={handleLogic}>Run Dummy Logic</button>
      {result && <p>{result}</p>}
    </div>
  );
};

export default RedisPage;
